import {useQueryClient} from "react-query";
import {useSelector} from "react-redux";
import {useMemo} from "react";

const useTranslations = () => {
    const queryClient = useQueryClient();
    const content = queryClient.getQueryData("contents");
    const language = useSelector(state => state.ui.language);

    //GENERAL
    const nothingFound = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "General.NothingFound")?.content
        } else {
            return content?.find(e => e.slug === "General.NothingFound")?.translations[language]?.name
        }
    }, [language, content])
    const noMatch = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "General.NoMatch")?.content
        } else {
            return content?.find(e => e.slug === "General.NoMatch")?.translations[language]?.name
        }
    }, [language, content])
    const selectNoOptions = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Select.NoOptions")?.content
        } else {
            return content?.find(e => e.slug === "Select.NoOptions")?.translations[language]?.name
        }
    }, [language, content])
    const position = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Text.Position")?.content
        } else {
            return content?.find(e => e.slug === "Text.Position")?.translations[language]?.name
        }
    }, [language, content])
    const align = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Text.Align")?.content
        } else {
            return content?.find(e => e.slug === "Text.Align")?.translations[language]?.name
        }
    }, [language, content])
    const lack = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Text.Lack")?.content
        } else {
            return content?.find(e => e.slug === "Text.Lack")?.translations[language]?.name
        }
    }, [language, content])
    const alignLeft = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Text.AlignLeft")?.content
        } else {
            return content?.find(e => e.slug === "Text.AlignLeft")?.translations[language]?.name
        }
    }, [language, content])
    const alignRight = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Text.AlignRight")?.content
        } else {
            return content?.find(e => e.slug === "Text.AlignRight")?.translations[language]?.name
        }
    }, [language, content])
    const alignTop = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Text.AlignTop")?.content
        } else {
            return content?.find(e => e.slug === "Text.AlignTop")?.translations[language]?.name
        }
    }, [language, content])
    const alignCenter = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Text.AlignCenter")?.content
        } else {
            return content?.find(e => e.slug === "Text.AlignCenter")?.translations[language]?.name
        }
    }, [language, content])
    const collapseText = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Text.Collapse")?.content
        } else {
            return content?.find(e => e.slug === "Text.Collapse")?.translations[language]?.name
        }
    }, [language, content])
    const expandText = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Text.Expand")?.content
        } else {
            return content?.find(e => e.slug === "Text.Expand")?.translations[language]?.name
        }
    }, [language, content])
    const noElementsToDisplay = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Text.NoElementsToDisplay")?.content
        } else {
            return content?.find(e => e.slug === "Text.NoElementsToDisplay")?.translations[language]?.name
        }
    }, [language, content])
    const downloadPdfButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.DownloadPdf")?.content
        } else {
            return content?.find(e => e.slug === "Button.DownloadPdf")?.translations[language]?.name
        }
    }, [language, content])
    const downloadClientPdfButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.DownloadClientPdf")?.content
        } else {
            return content?.find(e => e.slug === "Button.DownloadClientPdf")?.translations[language]?.name
        }
    }, [language, content])
    const downloadOnePdfButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.DownloadOnePdf")?.content
        } else {
            return content?.find(e => e.slug === "Button.DownloadOnePdf")?.translations[language]?.name
        }
    }, [language, content])
    const createOfferFromOrderButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.CreateOfferFromOrder")?.content
        } else {
            return content?.find(e => e.slug === "Button.CreateOfferFromOrder")?.translations[language]?.name
        }
    }, [language, content])
    const downloadTwoPdfButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.DownloadTwoPdf")?.content
        } else {
            return content?.find(e => e.slug === "Button.DownloadTwoPdf")?.translations[language]?.name
        }
    }, [language, content])
    const editButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.Edit")?.content
        } else {
            return content?.find(e => e.slug === "Button.Edit")?.translations[language]?.name
        }
    }, [language, content])
    const copyButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.Copy")?.content
        } else {
            return content?.find(e => e.slug === "Button.Copy")?.translations[language]?.name
        }
    }, [language, content])
    const copiedButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.Copied")?.content
        } else {
            return content?.find(e => e.slug === "Button.Copied")?.translations[language]?.name
        }
    }, [language, content])
    const deleteButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.Delete")?.content
        } else {
            return content?.find(e => e.slug === "Button.Delete")?.translations[language]?.name
        }
    }, [language, content])
    const saveButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.Save")?.content
        } else {
            return content?.find(e => e.slug === "Button.Save")?.translations[language]?.name
        }
    }, [language, content])
    const chooseButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.Choose")?.content
        } else {
            return content?.find(e => e.slug === "Button.Choose")?.translations[language]?.name
        }
    }, [language, content])
    const chosenButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.Chosen")?.content
        } else {
            return content?.find(e => e.slug === "Button.Chosen")?.translations[language]?.name
        }
    }, [language, content])

    //CREATOR
    const creatorStep0Title = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorStep0.Title")?.content
        } else {
            return content?.find(e => e.slug === "CreatorStep0.Title")?.translations[language]?.name
        }
    }, [language, content])
    const creatorStep0Description = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorStep0.Description")?.content
        } else {
            return content?.find(e => e.slug === "CreatorStep0.Description")?.translations[language]?.name
        }
    }, [language, content])
    const creatorStep1Title = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorStep1.Title")?.content
        } else {
            return content?.find(e => e.slug === "CreatorStep1.Title")?.translations[language]?.name
        }
    }, [language, content])
    const creatorStep1Description = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorStep1.Description")?.content
        } else {
            return content?.find(e => e.slug === "CreatorStep1.Description")?.translations[language]?.name
        }
    }, [language, content])
    const creatorStep0Accessories = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorStep1.Accessories")?.content
        } else {
            return content?.find(e => e.slug === "CreatorStep1.Accessories")?.translations[language]?.name
        }
    }, [language, content])

    //CREATOR ITEM DETAIL
    const creatorItemDetailsWidth = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.Width")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.Width")?.translations[language]?.name
        }
    }, [language, content])
    const creatorItemDetailsWidthPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.WidthPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.WidthPlaceholder")?.translations[language]?.name
        }
    }, [language, content])
    const creatorItemDetailsHeight = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.Height")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.Height")?.translations[language]?.name
        }
    }, [language, content])
    const creatorItemDetailsHeightPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.HeightPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.HeightPlaceholder")?.translations[language]?.name
        }
    }, [language, content])
    const creatorItemDetailsLintel = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.Lintel")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.Lintel")?.translations[language]?.name
        }
    }, [language, content])
    const creatorItemDetailsLintelPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.LintelPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.LintelPlaceholder")?.translations[language]?.name
        }
    }, [language, content])
    const creatorItemDetailsLeading = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.Leading")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.Leading")?.translations[language]?.name
        }
    }, [language, content])
    const creatorItemDetailsLeadingPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.LeadingPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.LeadingPlaceholder")?.translations[language]?.name
        }
    }, [language, content])
    const creatorItemDetailsDepth = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.Depth")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.Depth")?.translations[language]?.name
        }
    }, [language, content])
    const creatorItemDetailsDepthPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.DepthPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.DepthPlaceholder")?.translations[language]?.name
        }
    }, [language, content])
    const creatorItemDetailsLeftArea = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.LeftArea")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.LeftArea")?.translations[language]?.name
        }
    }, [language, content])
    const creatorItemDetailsLeftAreaPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.LeftAreaPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.LeftAreaPlaceholder")?.translations[language]?.name
        }
    }, [language, content])
    const creatorItemDetailsRightArea = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.RightArea")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.RightArea")?.translations[language]?.name
        }
    }, [language, content])
    const creatorItemDetailsRightAreaPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CreatorItemDetails.RightAreaPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "CreatorItemDetails.RightAreaPlaceholder")?.translations[language]?.name
        }
    }, [language, content])

    //DRIVE
    const driveNoDrives = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Drive.NoDrives")?.content
        } else {
            return content?.find(e => e.slug === "Drive.NoDrives")?.translations[language]?.name
        }
    }, [language, content])
    const driveTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Drive.Title")?.content
        } else {
            return content?.find(e => e.slug === "Drive.Title")?.translations[language]?.name
        }
    }, [language, content])
    const driveDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Drive.Description")?.content
        } else {
            return content?.find(e => e.slug === "Drive.Description")?.translations[language]?.name
        }
    }, [language, content])

    //PANEL
    const panelTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Panel.Title")?.content
        } else {
            return content?.find(e => e.slug === "Panel.Title")?.translations[language]?.name
        }
    }, [language, content])
    const panelDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Panel.Description")?.content
        } else {
            return content?.find(e => e.slug === "Panel.Description")?.translations[language]?.name
        }
    }, [language, content])
    const panelNoColors = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Panel.NoColors")?.content
        } else {
            return content?.find(e => e.slug === "Panel.NoColors")?.translations[language]?.name
        }
    }, [language, content])

    //GATE ACCESSORIES
    const gateAccessoriesTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "GateAccessories.Title")?.content
        } else {
            return content?.find(e => e.slug === "GateAccessories.Title")?.translations[language]?.name
        }
    }, [language, content])
    const gateAccessoriesDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "GateAccessories.Description")?.content
        } else {
            return content?.find(e => e.slug === "GateAccessories.Description")?.translations[language]?.name
        }
    }, [language, content])
    const gateAccessoriesSearch = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "GateAccessories.Search")?.content
        } else {
            return content?.find(e => e.slug === "GateAccessories.Search")?.translations[language]?.name
        }
    }, [language, content])
    const editClientTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Accessories.EditClientTitle")?.content
        } else {
            return content?.find(e => e.slug === "Accessories.EditClientTitle")?.translations[language]?.name
        }
    }, [language, content])
    //ACCESSORIES
    const accessoriesTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Accessories.Title")?.content
        } else {
            return content?.find(e => e.slug === "Accessories.Title")?.translations[language]?.name
        }
    }, [language, content])
    const accessoriesDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Accessories.Description")?.content
        } else {
            return content?.find(e => e.slug === "Accessories.Description")?.translations[language]?.name
        }
    }, [language, content])
    const accessoriesSearch = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Accessories.Search")?.content
        } else {
            return content?.find(e => e.slug === "Accessories.Search")?.translations[language]?.name
        }
    }, [language, content])

    //SUMMARY

    const summarySummary = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.Summary")?.content
        } else {
            return content?.find(e => e.slug === "Summary.Summary")?.translations[language]?.name
        }
    }, [language, content])

    //SUMMARYBOX
    const summaryBoxTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.Title")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.Title")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxKtmDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.KtmDescription")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.KtmDescription")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxKtmAccessories = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.KtmAccessories")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.KtmAccessories")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxCustomPrice = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.CustomPrice")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.CustomPrice")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxWidth = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.Width")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.Width")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxRoofAngle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.RoofAngle")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.RoofAngle")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxGateAccessories = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.GateAccessories")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.GateAccessories")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxNext = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.Next")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.Next")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxAddToCart = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.AddToCart")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.AddToCart")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxSummary = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.Summary")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.Summary")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxSave = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.Save")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.Save")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxBack = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.Back")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.Back")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxExtraAccessories = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.ExtraAccessories")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.ExtraAccessories")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxRightArea = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.RightArea")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.RightArea")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxLeftArea = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.LeftArea")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.LeftArea")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxLintel = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.Lintel")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.Lintel")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxLeading = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.Leading")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.Leading")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxDepth = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.Depth")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.Depth")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxHeight = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.Height")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.Height")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxStep = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.Step")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.Step")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxPrice = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.Price")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.Price")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxNetPrice = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.NetPrice")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.NetPrice")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxKtmAccessoriesDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.KtmAccessoriesDescription")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.KtmAccessoriesDescription")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxGateType = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.GateType")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.GateType")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxDrive = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.Drive")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.Drive")?.translations[language]?.name
        }
    }, [language, content])
    const summaryBoxShorKTM = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "SummaryBox.ShorKTM")?.content
        } else {
            return content?.find(e => e.slug === "SummaryBox.ShorKTM")?.translations[language]?.name
        }
    }, [language, content])

    //LOGIN
    const loginPageTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "LoginPage.Title")?.content
        } else {
            return content?.find(e => e.slug === "LoginPage.Title")?.translations[language]?.name
        }
    }, [language, content])

    const loginPageEmailLabel = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "LoginPage.EmailLabel")?.content
        } else {
            return content?.find(e => e.slug === "LoginPage.EmailLabel")?.translations[language]?.name
        }
    }, [language, content])

    const loginPageEmailError = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "LoginPage.EmailError")?.content
        } else {
            return content?.find(e => e.slug === "LoginPage.EmailError")?.translations[language]?.name
        }
    }, [language, content])

    const loginPageEmailPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "LoginPage.EmailPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "LoginPage.EmailPlaceholder")?.translations[language]?.name
        }
    }, [language, content])

    const loginPagePasswordLabel = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "LoginPage.PasswordLabel")?.content
        } else {
            return content?.find(e => e.slug === "LoginPage.PasswordLabel")?.translations[language]?.name
        }
    }, [language, content])
    const loginPagePasswordError = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "LoginPage.PasswordError")?.content
        } else {
            return content?.find(e => e.slug === "LoginPage.PasswordError")?.translations[language]?.name
        }
    }, [language, content])
    const loginPagePasswordPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "LoginPage.PasswordPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "LoginPage.PasswordPlaceholder")?.translations[language]?.name
        }
    }, [language, content])
    const loginButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.Login")?.content
        } else {
            return content?.find(e => e.slug === "Button.Login")?.translations[language]?.name
        }
    }, [language, content])

    const forgotPassword = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "LoginPage.ForgotPassword")?.content
        } else {
            return content?.find(e => e.slug === "LoginPage.ForgotPassword")?.translations[language]?.name
        }
    }, [language, content])
    const forgotPasswordText1 = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "LoginPage.ForgotPasswordText1")?.content
        } else {
            return content?.find(e => e.slug === "LoginPage.ForgotPasswordText1")?.translations[language]?.name
        }
    }, [language, content])
    const register = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "LoginPage.Register")?.content
        } else {
            return content?.find(e => e.slug === "LoginPage.Register")?.translations[language]?.name
        }
    }, [language, content])


    //WELCOME PAGE
    const welcomeText = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "WelcomePage.Welcome")?.content
        } else {
            return content?.find(e => e.slug === "WelcomePage.Welcome")?.translations[language]?.name
        }
    }, [language, content])
    const offers = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "WelcomePage.Offers")?.content
        } else {
            return content?.find(e => e.slug === "WelcomePage.Offers")?.translations[language]?.name
        }
    }, [language, content])
    const newOffer = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "WelcomePage.NewOffer")?.content
        } else {
            return content?.find(e => e.slug === "WelcomePage.NewOffer")?.translations[language]?.name
        }
    }, [language, content])
    const offerHistory = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "WelcomePage.OfferHistory")?.content
        } else {
            return content?.find(e => e.slug === "WelcomePage.OfferHistory")?.translations[language]?.name
        }
    }, [language, content])
    const offerBasket = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "WelcomePage.OfferBasket")?.content
        } else {
            return content?.find(e => e.slug === "WelcomePage.OfferBasket")?.translations[language]?.name
        }
    }, [language, content])
    const orders = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "WelcomePage.Orders")?.content
        } else {
            return content?.find(e => e.slug === "WelcomePage.Orders")?.translations[language]?.name
        }
    }, [language, content])
    const newOrder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "WelcomePage.NewOrders")?.content
        } else {
            return content?.find(e => e.slug === "WelcomePage.NewOrders")?.translations[language]?.name
        }
    }, [language, content])
    const orderHistory = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "WelcomePage.OrderHistory")?.content
        } else {
            return content?.find(e => e.slug === "WelcomePage.OrderHistory")?.translations[language]?.name
        }
    }, [language, content])
    const orderBasket = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "WelcomePage.OrderBasket")?.content
        } else {
            return content?.find(e => e.slug === "WelcomePage.OrderBasket")?.translations[language]?.name
        }
    }, [language, content])
    const clients = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "WelcomePage.Clients")?.content
        } else {
            return content?.find(e => e.slug === "WelcomePage.Clients")?.translations[language]?.name
        }
    }, [language, content])
    const newClient = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "WelcomePage.NewClient")?.content
        } else {
            return content?.find(e => e.slug === "WelcomePage.NewClient")?.translations[language]?.name
        }
    }, [language, content])
    const clientsList = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "WelcomePage.ClientsList")?.content
        } else {
            return content?.find(e => e.slug === "WelcomePage.ClientsList")?.translations[language]?.name
        }
    }, [language, content])
    const clientsOrders = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "WelcomePage.ClientsOrders")?.content
        } else {
            return content?.find(e => e.slug === "WelcomePage.ClientsOrders")?.translations[language]?.name
        }
    }, [language, content])

    //USER DATA FORM
    const editData = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.EditData")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.EditData")?.translations[language]?.name
        }
    }, [language, content])
    const editEmail = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.EditEmail")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.EditEmail")?.translations[language]?.name
        }
    }, [language, content])
    const editPassword = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.EditPassword")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.EditPassword")?.translations[language]?.name
        }
    }, [language, content])
    const editDataDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.EditDataDescription")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.EditDataDescription")?.translations[language]?.name
        }
    }, [language, content])
    const editEmailDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.EditEmailDescription")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.EditEmailDescription")?.translations[language]?.name
        }
    }, [language, content])
    const editPasswordDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.EditPasswordDescription")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.EditPasswordDescription")?.translations[language]?.name
        }
    }, [language, content])
    const userDataName = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.Name")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.Name")?.translations[language]?.name
        }
    }, [language, content])
    const userDataSurname = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.Surname")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.Surname")?.translations[language]?.name
        }
    }, [language, content])
    const userDataPhone = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.Phone")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.Phone")?.translations[language]?.name
        }
    }, [language, content])
    const userDataCurrentPassword = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.CurrentPassword")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.CurrentPassword")?.translations[language]?.name
        }
    }, [language, content])
    const userDataNewPassword = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.NewPassword")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.NewPassword")?.translations[language]?.name
        }
    }, [language, content])
    const userDataNewPasswordPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.NewPasswordPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.NewPasswordPlaceholder")?.translations[language]?.name
        }
    }, [language, content])
    const userDataRepeatNewPassword = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.RepeatNewPassword")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.RepeatNewPassword")?.translations[language]?.name
        }
    }, [language, content])
    const userDataRepeatNewPasswordPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.RepeatNewPasswordPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.RepeatNewPasswordPlaceholder")?.translations[language]?.name
        }
    }, [language, content])
    const userDataCurrentPasswordPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.CurrentPasswordPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.CurrentPasswordPlaceholder")?.translations[language]?.name
        }
    }, [language, content])
    const userDataCurrentPasswordError = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.CurrentPasswordError")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.CurrentPasswordError")?.translations[language]?.name
        }
    }, [language, content])
    const userDataCurrentPasswordError2 = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.CurrentPasswordError2")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.CurrentPasswordError2")?.translations[language]?.name
        }
    }, [language, content])
    const userDataNewEmail = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.NewEmail")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.NewEmail")?.translations[language]?.name
        }
    }, [language, content])
    const userDataRepeatEmail = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.RepeatEmail")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.RepeatEmail")?.translations[language]?.name
        }
    }, [language, content])
    const userDataLogoutMessage = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.LogoutMessage")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.LogoutMessage")?.translations[language]?.name
        }
    }, [language, content])
    const userDataAccountDeactivate = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "UserDataForm.AccountDeactivate")?.content
        } else {
            return content?.find(e => e.slug === "UserDataForm.AccountDeactivate")?.translations[language]?.name
        }
    }, [language, content])

    //FOOTER
    const footerSecretariat = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Footer.Secretariat")?.content
        } else {
            return content?.find(e => e.slug === "Footer.Secretariat")?.translations[language]?.name
        }
    }, [language, content])
    const footerExport = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Footer.Export")?.content
        } else {
            return content?.find(e => e.slug === "Footer.Export")?.translations[language]?.name
        }
    }, [language, content])
    const footerMarketing = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Footer.Marketing")?.content
        } else {
            return content?.find(e => e.slug === "Footer.Marketing")?.translations[language]?.name
        }
    }, [language, content])
    const footerReturns = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Footer.Returns")?.content
        } else {
            return content?.find(e => e.slug === "Footer.Returns")?.translations[language]?.name
        }
    }, [language, content])
    const footerSteelSale = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Footer.SteelSale")?.content
        } else {
            return content?.find(e => e.slug === "Footer.SteelSale")?.translations[language]?.name
        }
    }, [language, content])
    const footerWoodSale = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Footer.WoodSale")?.content
        } else {
            return content?.find(e => e.slug === "Footer.WoodSale")?.translations[language]?.name
        }
    }, [language, content])
    const footerPrintReturn = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Footer.PrintReturn")?.content
        } else {
            return content?.find(e => e.slug === "Footer.PrintReturn")?.translations[language]?.name
        }
    }, [language, content])

    //CART
    const cartOfferTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.OfferTitle")?.content
        } else {
            return content?.find(e => e.slug === "Cart.OfferTitle")?.translations[language]?.name
        }
    }, [language, content])
    const cartOfferDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.OfferDescription")?.content
        } else {
            return content?.find(e => e.slug === "Cart.OfferDescription")?.translations[language]?.name
        }
    }, [language, content])
    const cartOrderTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.OrderTitle")?.content
        } else {
            return content?.find(e => e.slug === "Cart.OrderTitle")?.translations[language]?.name
        }
    }, [language, content])
    const cartOrderDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.OrderDescription")?.content
        } else {
            return content?.find(e => e.slug === "Cart.OrderDescription")?.translations[language]?.name
        }
    }, [language, content])
    const cartSearchPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.SearchPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "Cart.SearchPlaceholder")?.translations[language]?.name
        }
    }, [language, content])
    const cartEmptyCart = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.EmptyCart")?.content
        } else {
            return content?.find(e => e.slug === "Cart.EmptyCart")?.translations[language]?.name
        }
    }, [language, content])
    const cartAddNewProject = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.AddNewProject")?.content
        } else {
            return content?.find(e => e.slug === "Cart.AddNewProject")?.translations[language]?.name
        }
    }, [language, content])
    const cartBasketSummary = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.BasketSummary")?.content
        } else {
            return content?.find(e => e.slug === "Cart.BasketSummary")?.translations[language]?.name
        }
    }, [language, content])
    const cartPriceNet = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.PriceNet")?.content
        } else {
            return content?.find(e => e.slug === "Cart.PriceNet")?.translations[language]?.name
        }
    }, [language, content])
    const cartDiscountNet = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.DiscountNet")?.content
        } else {
            return content?.find(e => e.slug === "Cart.DiscountNet")?.translations[language]?.name
        }
    }, [language, content])
    const cartVat = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.Vat")?.content
        } else {
            return content?.find(e => e.slug === "Cart.Vat")?.translations[language]?.name
        }
    }, [language, content])
    const cartDiscountNetPrice = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.DiscountNetPrice")?.content
        } else {
            return content?.find(e => e.slug === "Cart.DiscountNetPrice")?.translations[language]?.name
        }
    }, [language, content])
    const cartDiscountPrice = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.DiscountPrice")?.content
        } else {
            return content?.find(e => e.slug === "Cart.DiscountPrice")?.translations[language]?.name
        }
    }, [language, content])

    const cartChooseOrderButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.ChooseOrderButton")?.content
        } else {
            return content?.find(e => e.slug === "Cart.ChooseOrderButton")?.translations[language]?.name
        }
    }, [language, content])
    const cartOrderConfirmButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.OrderConfirmButton")?.content
        } else {
            return content?.find(e => e.slug === "Cart.OrderConfirmButton")?.translations[language]?.name
        }
    }, [language, content])
    const cartOfferConfirmButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.OfferConfirmButton")?.content
        } else {
            return content?.find(e => e.slug === "Cart.OfferConfirmButton")?.translations[language]?.name
        }
    }, [language, content])

    const cartChooseOfferButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.ChooseOfferButton")?.content
        } else {
            return content?.find(e => e.slug === "Cart.ChooseOfferButton")?.translations[language]?.name
        }
    }, [language, content])
    const cartDiscountProcentage = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.DiscountProcentage")?.content
        } else {
            return content?.find(e => e.slug === "Cart.DiscountProcentage")?.translations[language]?.name
        }
    }, [language, content])
    const cartAccessoriesDiscountProcentage = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.AccessoriesDiscountProcentage")?.content
        } else {
            return content?.find(e => e.slug === "Cart.AccessoriesDiscountProcentage")?.translations[language]?.name
        }
    }, [language, content])
    const cartMontageCost = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.MontageCost")?.content
        } else {
            return content?.find(e => e.slug === "Cart.MontageCost")?.translations[language]?.name
        }
    }, [language, content])
    const cartEmptyClient = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.EmptyClient")?.content
        } else {
            return content?.find(e => e.slug === "Cart.EmptyClient")?.translations[language]?.name
        }
    }, [language, content])
    const cartOrderError = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Cart.OrderError")?.content
        } else {
            return content?.find(e => e.slug === "Cart.OrderError")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsCompany = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.Company")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.Company")?.translations[language]?.name
        }
    }, [language, content])
    const cartCreatedAt = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.CreatedAt")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.CreatedAt")?.translations[language]?.name
        }
    }, [language, content])
    const cartName = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.Name")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.Name")?.translations[language]?.name
        }
    }, [language, content])
    const cartStatus = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.Status")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.Status")?.translations[language]?.name
        }
    }, [language, content])
    const cartNetCatalogPrice = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.NetCatalogPrice")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.NetCatalogPrice")?.translations[language]?.name
        }
    }, [language, content])
    const cartNetPrice = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.NetPrice")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.NetPrice")?.translations[language]?.name
        }
    }, [language, content])
    const cartCartStatusNew = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.CartStatusNew")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.CartStatusNew")?.translations[language]?.name
        }
    }, [language, content])


    const cartItemsKTMCode = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.KTMCode")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.KTMCode")?.translations[language]?.name
        }
    }, [language, content])

    const cartItemsKTMDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.KTMDescription")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.KTMDescription")?.translations[language]?.name
        }
    }, [language, content])

    const cartItemsKTMQty = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.KTMQty")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.KTMQty")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsGateType = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.GateType")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.GateType")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsWidth = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.Width")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.Width")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsHeight = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.Height")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.Height")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsDepth = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.Depth")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.Depth")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsAreaLeft = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.AreaLeft")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.AreaLeft")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsAreaRight = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.AreaRight")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.AreaRight")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsLintel = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.Lintel")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.Lintel")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsDrive = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.Drive")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.Drive")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsLeading = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.Leading")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.Leading")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsRoofAngle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.RoofAngle")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.RoofAngle")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsGateAccessories = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.GateAccessories")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.GateAccessories")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsTax = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.Tax")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.Tax")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsTaxPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.TaxPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.TaxPlaceholder")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsEmail = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.Email")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.Email")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsPhone = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.Phone")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.Phone")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsDiscountAmountTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItemsDiscountAmount.Title")?.content
        } else {
            return content?.find(e => e.slug === "CartItemsDiscountAmount.Title")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsDiscountAmountTooltip = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItemsDiscountAmount.Tooltip")?.content
        } else {
            return content?.find(e => e.slug === "CartItemsDiscountAmount.Tooltip")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsMontageCostTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItemsMontageCost.Title")?.content
        } else {
            return content?.find(e => e.slug === "CartItemsDiscountAmount.Title")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsMontageCostPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItemsMontageCost.Placeholder")?.content
        } else {
            return content?.find(e => e.slug === "CartItemsDiscountAmount.Placeholder")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsPrice = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.Price")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.Price")?.translations[language]?.name
        }
    }, [language, content])
    const cartItemsAccName = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartItems.AccName")?.content
        } else {
            return content?.find(e => e.slug === "CartItems.AccName")?.translations[language]?.name
        }
    }, [language, content])

    //HISTORY
    const historyOrderTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.OrderTitle")?.content
        } else {
            return content?.find(e => e.slug === "History.Title")?.translations[language]?.name
        }
    }, [language, content])
    const historyOfferTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.OfferTitle")?.content
        } else {
            return content?.find(e => e.slug === "History.OfferTitle")?.translations[language]?.name
        }
    }, [language, content])
    const historyDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.Description")?.content
        } else {
            return content?.find(e => e.slug === "History.Description")?.translations[language]?.name
        }
    }, [language, content])
    const historyFilterPlaceholder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.FilterPlaceholder")?.content
        } else {
            return content?.find(e => e.slug === "History.FilterPlaceholder")?.translations[language]?.name
        }
    }, [language, content])
    const historyFilterStatusNew = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.FilterStatusNew")?.content
        } else {
            return content?.find(e => e.slug === "History.FilterStatusNew")?.translations[language]?.name
        }
    }, [language, content])
    const historyFilterStatusConfirmedByDistributor = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.FilterStatusConfirmedByDistributor")?.content
        } else {
            return content?.find(e => e.slug === "History.FilterStatusConfirmedByDistributor")?.translations[language]?.name
        }
    }, [language, content])
    const historyFilterStatusChanged = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.FilterStatusChanged")?.content
        } else {
            return content?.find(e => e.slug === "History.FilterStatusChanged")?.translations[language]?.name
        }
    }, [language, content])
    const historyDuplicateButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.DuplicateButton")?.content
        } else {
            return content?.find(e => e.slug === "History.DuplicateButton")?.translations[language]?.name
        }
    }, [language, content])
    const historyDuplicateSuccess = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.DuplicateSuccess")?.content
        } else {
            return content?.find(e => e.slug === "History.DuplicateSuccess")?.translations[language]?.name
        }
    }, [language, content])
    const historyFilterStatusSent = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.FilterStatusSent")?.content
        } else {
            return content?.find(e => e.slug === "History.FilterStatusSent")?.translations[language]?.name
        }
    }, [language, content])
    const historyFilterStatusConfirmed = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.FilterStatusConfirmed")?.content
        } else {
            return content?.find(e => e.slug === "History.FilterStatusConfirmed")?.translations[language]?.name
        }
    }, [language, content])
    const historyFilterStatusCanceled = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.FilterStatusCanceled")?.content
        } else {
            return content?.find(e => e.slug === "History.FilterStatusCanceled")?.translations[language]?.name
        }
    }, [language, content])
    const historyFilterStatusSentToDistributor = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.FilterStatusSentToDistributor")?.content
        } else {
            return content?.find(e => e.slug === "History.FilterStatusSentToDistributor")?.translations[language]?.name
        }
    }, [language, content])
    const historyEmpty = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.Empty")?.content
        } else {
            return content?.find(e => e.slug === "History.Empty")?.translations[language]?.name
        }
    }, [language, content])
    const historyOrderNumber = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.OrderNumber")?.content
        } else {
            return content?.find(e => e.slug === "History.OrderNumber")?.translations[language]?.name
        }
    }, [language, content])
    const historyOfferNumber = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.OfferNumber")?.content
        } else {
            return content?.find(e => e.slug === "History.OfferNumber")?.translations[language]?.name
        }
    }, [language, content])
    const historyValidUntil = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.ValidUntil")?.content
        } else {
            return content?.find(e => e.slug === "History.ValidUntil")?.translations[language]?.name
        }
    }, [language, content])
    const historyNetValue = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.NetValue")?.content
        } else {
            return content?.find(e => e.slug === "History.NetValue")?.translations[language]?.name
        }
    }, [language, content])
    const historyRealizationDate = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.RealizationDate")?.content
        } else {
            return content?.find(e => e.slug === "History.RealizationDate")?.translations[language]?.name
        }
    }, [language, content])
    const historyDistributor = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.Distributor")?.content
        } else {
            return content?.find(e => e.slug === "History.Distributor")?.translations[language]?.name
        }
    }, [language, content])
    const historyOffer = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.Offer")?.content
        } else {
            return content?.find(e => e.slug === "History.Offer")?.translations[language]?.name
        }
    }, [language, content])
    const historyClientCompany = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.ClientCompany")?.content
        } else {
            return content?.find(e => e.slug === "History.ClientCompany")?.translations[language]?.name
        }
    }, [language, content])
    const historyClientName = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.ClientName")?.content
        } else {
            return content?.find(e => e.slug === "History.ClientName")?.translations[language]?.name
        }
    }, [language, content])
    const historyOrderInternalNumber = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.OrderInternalNumber")?.content
        } else {
            return content?.find(e => e.slug === "History.OrderInternalNumber")?.translations[language]?.name
        }
    }, [language, content])
    const historyOfferInternalNumber = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.OfferInternalNumber")?.content
        } else {
            return content?.find(e => e.slug === "History.OfferInternalNumber")?.translations[language]?.name
        }
    }, [language, content])
    const historyClientAddress = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.ClientAddress")?.content
        } else {
            return content?.find(e => e.slug === "History.ClientAddress")?.translations[language]?.name
        }
    }, [language, content])
    const historyClientZipCode = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.ClientZipCode")?.content
        } else {
            return content?.find(e => e.slug === "History.ClientZipCode")?.translations[language]?.name
        }
    }, [language, content])
    const historyClientCity = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.ClientCity")?.content
        } else {
            return content?.find(e => e.slug === "History.ClientCity")?.translations[language]?.name
        }
    }, [language, content])
    const historyClientCountry = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.ClientCountry")?.content
        } else {
            return content?.find(e => e.slug === "History.ClientCountry")?.translations[language]?.name
        }
    }, [language, content])
    const historyClientPhone = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.ClientPhone")?.content
        } else {
            return content?.find(e => e.slug === "History.ClientPhone")?.translations[language]?.name
        }
    }, [language, content])
    const historyClientEmail = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.ClientEmail")?.content
        } else {
            return content?.find(e => e.slug === "History.ClientEmail")?.translations[language]?.name
        }
    }, [language, content])
    const historyNewProject = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.NewProject")?.content
        } else {
            return content?.find(e => e.slug === "History.NewProject")?.translations[language]?.name
        }
    }, [language, content])
    const historyKTMDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.KTMDescription")?.content
        } else {
            return content?.find(e => e.slug === "History.KTMDescription")?.translations[language]?.name
        }
    }, [language, content])
    const historyQty = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.Qty")?.content
        } else {
            return content?.find(e => e.slug === "History.Qty")?.translations[language]?.name
        }
    }, [language, content])
    const historyOrderFromOffer = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.OrderFromOffer")?.content
        } else {
            return content?.find(e => e.slug === "History.OrderFromOffer")?.translations[language]?.name
        }
    }, [language, content])
    const historyOrderFromOfferError = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.OrderFromOfferError")?.content
        } else {
            return content?.find(e => e.slug === "History.OrderFromOfferError")?.translations[language]?.name
        }
    }, [language, content])
    const historyDistributorCaclulation = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.DistributorCaclulation")?.content
        } else {
            return content?.find(e => e.slug === "History.DistributorCaclulation")?.translations[language]?.name
        }
    }, [language, content])
    const historyClientCaclulation = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.ClientCaclulation")?.content
        } else {
            return content?.find(e => e.slug === "History.ClientCaclulation")?.translations[language]?.name
        }
    }, [language, content])
    const historyFooterNetPrice = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.FooterNetPrice")?.content
        } else {
            return content?.find(e => e.slug === "History.FooterNetPrice")?.translations[language]?.name
        }
    }, [language, content])
    const historyFooterNetDiscountPrice = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.FooterNetDiscountPrice")?.content
        } else {
            return content?.find(e => e.slug === "History.FooterNetDiscountPrice")?.translations[language]?.name
        }
    }, [language, content])
    const historyFooterDiscount = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.FooterDiscount")?.content
        } else {
            return content?.find(e => e.slug === "History.FooterDiscount")?.translations[language]?.name
        }
    }, [language, content])
    const historyLastEdit = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.LastEdit")?.content
        } else {
            return content?.find(e => e.slug === "History.LastEdit")?.translations[language]?.name
        }
    }, [language, content])
    const historyFooterEmail = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.FooterEmail")?.content
        } else {
            return content?.find(e => e.slug === "History.FooterEmail")?.translations[language]?.name
        }
    }, [language, content])
    const historyFooterPhone = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "History.FooterPhone")?.content
        } else {
            return content?.find(e => e.slug === "History.FooterPhone")?.translations[language]?.name
        }
    }, [language, content])

    //NEW CLIENT
    const newClientTitleAdd = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.TitleAdd")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.TitleAdd")?.translations[language]?.name
        }
    }, [language, content])
    const newClientTitleEdit = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.TitleEdit")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.TitleEdit")?.translations[language]?.name
        }
    }, [language, content])
    const newClientDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.Description")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.Description")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompany = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.Company")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.Company")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompanyShort = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.CompanyShort")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.CompanyShort")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompanyName = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.Name")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.Name")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompanySurname = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.Surname")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.Surname")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompanyEmail = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.Email")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.Email")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompanyNumber = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.CompanyNumber")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.CompanyNumber")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompanyStreet = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.CompanyStreet")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.CompanyStreet")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompanyCity = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.CompanyCity")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.CompanyCity")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompanyZipCode = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.CompanyZipCode")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.CompanyZipCode")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompanyProvince = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.CompanyProvince")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.CompanyProvince")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompanyCountry = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.CompanyCountry")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.CompanyCountry")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompanyPhone = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.CompanyPhone")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.CompanyPhone")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompanyDiscount = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.CompanyDiscount")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.CompanyDiscount")?.translations[language]?.name
        }
    }, [language, content])
    const newClientCompanyAccDiscount = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "NewClient.CompanyAccDiscount")?.content
        } else {
            return content?.find(e => e.slug === "NewClient.CompanyAccDiscount")?.translations[language]?.name
        }
    }, [language, content])
    const generatePINButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.GeneratePIN")?.content
        } else {
            return content?.find(e => e.slug === "Button.GeneratePIN")?.translations[language]?.name
        }
    }, [language, content])
    const confirmButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.Confirm")?.content
        } else {
            return content?.find(e => e.slug === "Button.Confirm")?.translations[language]?.name
        }
    }, [language, content])
    const addClientButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.AddClient")?.content
        } else {
            return content?.find(e => e.slug === "Button.AddClient")?.translations[language]?.name
        }
    }, [language, content])
    const editClientButton = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Button.EditClient")?.content
        } else {
            return content?.find(e => e.slug === "Button.EditClient")?.translations[language]?.name
        }
    }, [language, content])

    //CLIENT LIST
    const clientListTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "ClientsList.Title")?.content
        } else {
            return content?.find(e => e.slug === "ClientsList.Title")?.translations[language]?.name
        }
    }, [language, content])
    const clientListDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "ClientsList.Description")?.content
        } else {
            return content?.find(e => e.slug === "ClientsList.Description")?.translations[language]?.name
        }
    }, [language, content])
    const clientListInternalNumber = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "ClientsList.InternalNumber")?.content
        } else {
            return content?.find(e => e.slug === "ClientsList.InternalNumber")?.translations[language]?.name
        }
    }, [language, content])
    const clientListCompanyNumber = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "ClientsList.CompanyNumber")?.content
        } else {
            return content?.find(e => e.slug === "ClientsList.CompanyNumber")?.translations[language]?.name
        }
    }, [language, content])
    const clientListDistributor = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "ClientsList.Distributor")?.content
        } else {
            return content?.find(e => e.slug === "ClientsList.Distributor")?.translations[language]?.name
        }
    }, [language, content])
    const clientListActions = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "ClientsList.Actions")?.content
        } else {
            return content?.find(e => e.slug === "ClientsList.Actions")?.translations[language]?.name
        }
    }, [language, content])

    //CLIENTS HISTORY
    const clientsHistoryTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "ClientsHistory.Title")?.content
        } else {
            return content?.find(e => e.slug === "ClientsHistory.Title")?.translations[language]?.name
        }
    }, [language, content])
    const clientsHistoryDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "ClientsHistory.Description")?.content
        } else {
            return content?.find(e => e.slug === "ClientsHistory.Description")?.translations[language]?.name
        }
    }, [language, content])

    //CART PREVIEW
    const cartPreviewDataToOrder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartPreview.DataToOrder")?.content
        } else {
            return content?.find(e => e.slug === "CartPreview.DataToOrder")?.translations[language]?.name
        }
    }, [language, content])
    const cartPreviewDataToOffer = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartPreview.DataToOffer")?.content
        } else {
            return content?.find(e => e.slug === "CartPreview.DataToOffer")?.translations[language]?.name
        }
    }, [language, content])
    const cartPreviewOneTimeOffer = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartPreview.OneTimeOffer")?.content
        } else {
            return content?.find(e => e.slug === "CartPreview.OneTimeOffer")?.translations[language]?.name
        }
    }, [language, content])
    const cartPreviewOrderInternalNumber = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartPreview.OrderInternalNumber")?.content
        } else {
            return content?.find(e => e.slug === "CartPreview.OrderInternalNumber")?.translations[language]?.name
        }
    }, [language, content])
    const cartPreviewOfferInternalNumber = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartPreview.OfferInternalNumber")?.content
        } else {
            return content?.find(e => e.slug === "CartPreview.OfferInternalNumber")?.translations[language]?.name
        }
    }, [language, content])
    const cartPreviewNoClients = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartPreview.NoClients")?.content
        } else {
            return content?.find(e => e.slug === "CartPreview.NoClients")?.translations[language]?.name
        }
    }, [language, content])
    const cartPreviewClientCounty = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartPreview.ClientCounty")?.content
        } else {
            return content?.find(e => e.slug === "CartPreview.ClientCounty")?.translations[language]?.name
        }
    }, [language, content])
    const cartPreviewClientEmail = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartPreview.ClientEmail")?.content
        } else {
            return content?.find(e => e.slug === "CartPreview.ClientEmail")?.translations[language]?.name
        }
    }, [language, content])
    const cartPreviewOrderedProject = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartPreview.OrderedProject")?.content
        } else {
            return content?.find(e => e.slug === "CartPreview.OrderedProject")?.translations[language]?.name
        }
    }, [language, content])
    const cartPreviewDistributorPrice = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartPreview.DistributorPrice")?.content
        } else {
            return content?.find(e => e.slug === "CartPreview.DistributorPrice")?.translations[language]?.name
        }
    }, [language, content])
    const cartPreviewOrderExtraInfo = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartPreview.OrderExtraInfo")?.content
        } else {
            return content?.find(e => e.slug === "CartPreview.OrderExtraInfo")?.translations[language]?.name
        }
    }, [language, content])
    const cartPreviewOfferExtraInfo = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartPreview.OfferExtraInfo")?.content
        } else {
            return content?.find(e => e.slug === "CartPreview.OfferExtraInfo")?.translations[language]?.name
        }
    }, [language, content])
    const cartPreviewValidUntil = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "CartPreview.ValidUntil")?.content
        } else {
            return content?.find(e => e.slug === "CartPreview.ValidUntil")?.translations[language]?.name
        }
    }, [language, content])

    //SUMMARY
    const summaryOfferTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.OfferTitle")?.content
        } else {
            return content?.find(e => e.slug === "Summary.OfferTitle")?.translations[language]?.name
        }
    }, [language, content])
    const summaryOrderTitle = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.OrderTitle")?.content
        } else {
            return content?.find(e => e.slug === "Summary.OrderTitle")?.translations[language]?.name
        }
    }, [language, content])
    const summaryDescription = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.Description")?.content
        } else {
            return content?.find(e => e.slug === "Summary.Description")?.translations[language]?.name
        }
    }, [language, content])
    const summaryInfoAbout = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.InfoAbout")?.content
        } else {
            return content?.find(e => e.slug === "Summary.InfoAbout")?.translations[language]?.name
        }
    }, [language, content])
    const summaryOffer = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.Offer")?.content
        } else {
            return content?.find(e => e.slug === "Summary.Offer")?.translations[language]?.name
        }
    }, [language, content])
    const summaryOrder = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.Order")?.content
        } else {
            return content?.find(e => e.slug === "Summary.Order")?.translations[language]?.name
        }
    }, [language, content])
    const summaryOrderNumber = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.OrderNumber")?.content
        } else {
            return content?.find(e => e.slug === "Summary.OrderNumber")?.translations[language]?.name
        }
    }, [language, content])
    const summaryOfferNumber = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.OfferNumber")?.content
        } else {
            return content?.find(e => e.slug === "Summary.OfferNumber")?.translations[language]?.name
        }
    }, [language, content])
    const summaryInternalOrderNumber = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.InternalOrderNumber")?.content
        } else {
            return content?.find(e => e.slug === "Summary.InternalOrderNumber")?.translations[language]?.name
        }
    }, [language, content])
    const summaryInternalOfferNumber = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.InternalOfferNumber")?.content
        } else {
            return content?.find(e => e.slug === "Summary.InternalOfferNumber")?.translations[language]?.name
        }
    }, [language, content])
    const summaryClient = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.Client")?.content
        } else {
            return content?.find(e => e.slug === "Summary.Client")?.translations[language]?.name
        }
    }, [language, content])
    const summaryDownloadPDF = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.DownloadPDF")?.content
        } else {
            return content?.find(e => e.slug === "Summary.DownloadPDF")?.translations[language]?.name
        }
    }, [language, content])
    const summaryDownloaded = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.Downloaded")?.content
        } else {
            return content?.find(e => e.slug === "Summary.Downloaded")?.translations[language]?.name
        }
    }, [language, content])
    const summaryOfferList = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.OfferList")?.content
        } else {
            return content?.find(e => e.slug === "Summary.OfferList")?.translations[language]?.name
        }
    }, [language, content])
    const summaryOrderList = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Summary.OrderList")?.content
        } else {
            return content?.find(e => e.slug === "Summary.OrderList")?.translations[language]?.name
        }
    }, [language, content])

    return {
        summaryOfferList,
        summaryOrderList,
        summaryDownloaded,
        summaryDownloadPDF,
        summaryClient,
        summaryInternalOrderNumber,
        summaryInternalOfferNumber,
        summaryOfferNumber,
        summaryOrderNumber,
        summaryOrder,
        summaryOffer,
        summaryInfoAbout,
        summaryDescription,
        summaryOfferTitle,
        summaryOrderTitle,
        cartPreviewValidUntil,
        cartPreviewOrderExtraInfo,
        cartPreviewOfferExtraInfo,
        cartPreviewDistributorPrice,
        cartPreviewOrderedProject,
        cartPreviewClientEmail,
        cartPreviewClientCounty,
        cartPreviewNoClients,
        cartPreviewOfferInternalNumber,
        cartPreviewOrderInternalNumber,
        cartPreviewOneTimeOffer,
        cartPreviewDataToOffer,
        cartPreviewDataToOrder,
        clientsHistoryDescription,
        clientsHistoryTitle,
        historyFilterStatusChanged,
        clientListActions,
        clientListDistributor,
        clientListCompanyNumber,
        clientListInternalNumber,
        clientListDescription,
        clientListTitle,
        addClientButton,
        editClientButton,
        generatePINButton,
        newClientCompanyAccDiscount,
        newClientCompanyDiscount,
        newClientCompanyPhone,
        newClientCompanyCountry,
        newClientCompanyProvince,
        newClientCompanyZipCode,
        newClientCompanyCity,
        newClientCompanyStreet,
        newClientCompanyNumber,
        newClientCompanyEmail,
        newClientCompanyShort,
        newClientCompanySurname,
        newClientCompanyName,
        newClientCompany,
        newClientDescription,
        newClientTitleAdd,
        newClientTitleEdit,
        createOfferFromOrderButton,
        downloadOnePdfButton,
        downloadTwoPdfButton,
        historyFooterPhone,
        historyFooterEmail,
        historyClientCaclulation,
        historyLastEdit,
        historyFooterNetDiscountPrice,
        historyFooterDiscount,
        historyFooterNetPrice,
        historyDistributorCaclulation,
        historyOrderFromOfferError,
        historyOrderFromOffer,
        downloadClientPdfButton,
        downloadPdfButton,
        historyQty,
        historyKTMDescription,
        historyNewProject,
        historyClientEmail,
        historyClientPhone,
        historyClientCountry,
        historyClientCity,
        historyClientZipCode,
        historyClientAddress,
        historyOrderInternalNumber,
        historyOfferInternalNumber,
        historyClientCompany,
        historyClientName,
        historyOffer,
        historyDistributor,
        historyRealizationDate,
        historyValidUntil,
        historyNetValue,
        historyOrderNumber,
        historyOfferNumber,
        historyEmpty,
        historyFilterStatusSentToDistributor,
        historyFilterStatusConfirmed,
        historyFilterStatusCanceled,
        historyFilterStatusSent,
        historyFilterStatusConfirmedByDistributor,
        historyFilterStatusNew,
        historyFilterPlaceholder,
        historyOrderTitle,
        historyOfferTitle,
        historyDescription,
        cartItemsAccName,
        cartItemsPrice,
        cartItemsMontageCostPlaceholder,
        cartItemsMontageCostTitle,
        cartItemsDiscountAmountTitle,
        cartItemsDiscountAmountTooltip,
        cartItemsTax,
        cartItemsEmail,
        cartItemsPhone,
        cartItemsTaxPlaceholder,
        cartItemsGateAccessories,
        cartCartStatusNew,
        cartNetPrice,
        cartNetCatalogPrice,
        cartStatus,
        cartName,
        cartCreatedAt,
        cartItemsCompany,
        cartOrderError,
        cartEmptyClient,
        cartOfferConfirmButton,
        cartOrderConfirmButton,
        cartChooseOrderButton,
        cartMontageCost,
        cartAccessoriesDiscountProcentage,
        cartDiscountProcentage,
        cartChooseOfferButton,
        historyDuplicateButton,
        cartDiscountNet,
        cartPriceNet,
        cartVat,
        cartDiscountNetPrice,
        cartDiscountPrice,
        cartBasketSummary,
        cartAddNewProject,
        cartEmptyCart,
        cartSearchPlaceholder,
        cartOfferTitle,
        cartOfferDescription,
        cartOrderTitle,
        cartOrderDescription,
        footerPrintReturn,
        footerSecretariat,
        footerExport,
        footerSteelSale,
        footerReturns,
        footerMarketing,
        footerWoodSale,
        content,
        language,
        welcomeText,
        offers,
        newOffer,
        offerHistory,
        offerBasket,
        orders,
        newOrder,
        orderHistory,
        orderBasket,
        clients,
        newClient,
        clientsList,
        clientsOrders,
        editData,
        editEmail,
        editPassword,
        editDataDescription,
        editEmailDescription,
        editPasswordDescription,
        loginPageTitle,
        loginPageEmailLabel,
        loginPageEmailError,
        loginPageEmailPlaceholder,
        loginPagePasswordLabel,
        loginPagePasswordError,
        loginPagePasswordPlaceholder,
        loginButton,
        forgotPassword,
        forgotPasswordText1,
        register,
        userDataName,
        userDataSurname,
        userDataPhone,
        userDataCurrentPassword,
        userDataCurrentPasswordPlaceholder,
        userDataNewEmail,
        userDataRepeatEmail,
        saveButton,
        userDataLogoutMessage,
        userDataNewPassword,
        userDataRepeatNewPassword,
        userDataNewPasswordPlaceholder,
        userDataRepeatNewPasswordPlaceholder,
        userDataCurrentPasswordError,
        userDataAccountDeactivate,
        creatorStep0Title,
        creatorStep0Description,
        creatorStep1Title,
        creatorStep1Description,
        creatorItemDetailsWidth,
        creatorItemDetailsWidthPlaceholder,
        creatorStep0Accessories,
        chooseButton,
        confirmButton,
        creatorItemDetailsHeight,
        creatorItemDetailsHeightPlaceholder,
        creatorItemDetailsLintelPlaceholder,
        creatorItemDetailsLintel,
        creatorItemDetailsLeadingPlaceholder,
        creatorItemDetailsLeading,
        creatorItemDetailsDepthPlaceholder,
        creatorItemDetailsDepth,
        creatorItemDetailsLeftAreaPlaceholder,
        creatorItemDetailsLeftArea,
        creatorItemDetailsRightAreaPlaceholder,
        creatorItemDetailsRightArea,
        summaryBoxTitle,
        summaryBoxKtmDescription,
        summaryBoxGateType,
        summaryBoxKtmAccessoriesDescription,
        summaryBoxKtmAccessories,
        summaryBoxNetPrice,
        summaryBoxPrice,
        summaryBoxCustomPrice,
        summaryBoxStep,
        summaryBoxWidth,
        summaryBoxHeight,
        summaryBoxDepth,
        summaryBoxRightArea,
        summaryBoxLeftArea,
        summaryBoxLintel,
        summaryBoxLeading,
        summaryBoxRoofAngle,
        summaryBoxGateAccessories,
        summaryBoxExtraAccessories,
        summaryBoxNext,
        summaryBoxBack,
        summaryBoxAddToCart,
        summaryBoxSummary,
        summaryBoxSave,
        driveTitle,
        driveDescription,
        chosenButton,
        panelTitle,
        panelDescription,
        panelNoColors,
        noElementsToDisplay,
        driveNoDrives,
        gateAccessoriesTitle,
        gateAccessoriesDescription,
        gateAccessoriesSearch,
        accessoriesTitle,
        accessoriesDescription,
        accessoriesSearch,
        summarySummary,
        collapseText,
        expandText,
        cartItemsKTMCode,
        cartItemsKTMDescription,
        cartItemsKTMQty,
        cartItemsGateType,
        cartItemsWidth,
        cartItemsHeight,
        cartItemsDepth,
        cartItemsAreaLeft,
        cartItemsAreaRight,
        cartItemsLintel,
        cartItemsLeading,
        cartItemsDrive,
        cartItemsRoofAngle,
        align,
        alignLeft,
        alignRight,
        alignTop,
        alignCenter,
        position,
        summaryBoxShorKTM,
        lack,
        selectNoOptions,
        editButton,
        copyButton,
        deleteButton,
        copiedButton,
        nothingFound,
        noMatch,
        summaryBoxDrive,
        historyDuplicateSuccess,
        editClientTitle
    }
}
export default useTranslations;
