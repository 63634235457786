import React, {useEffect, useMemo, useState} from 'react';

import expandIcon from '../../../assets/icons/expand.png';
import eyeIcon from '../../../assets/icons/eye.png';
import {motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {creatorActions} from "../../../store/slice/gateCreatorSlice";
import {useQuery} from "react-query";
import {authorizedDeleteWithId, authorizedGet, authorizedGetById} from "../CreatorFunctions";
import {uiActions} from "../../../store/slice/uiSlice";
import {useLocation} from "react-router-dom";
import useTranslations from "../../../hooks/useTranslations";
import {Checkbox, Select} from "@mantine/core";


const Accessories = () => {
    const {accessoriesSearch, cartPreviewDataToOffer, cartPreviewDataToOrder, cartPreviewOneTimeOffer, cartPreviewOfferInternalNumber,
        cartPreviewOrderInternalNumber, historyClientCompany, historyClientName, cartPreviewNoClients, historyClientAddress,
        historyClientCity, historyClientCountry, historyClientZipCode, historyClientPhone,
        cartPreviewClientCounty,cartPreviewClientEmail,cartPreviewOrderedProject,collapseText,
        expandText,cartItemsKTMCode,
        cartItemsKTMDescription,
        cartItemsKTMQty,
        cartItemsGateType,
        cartItemsWidth,
        cartItemsHeight,
        cartItemsDepth,
        cartItemsAreaLeft,
        cartItemsAreaRight,
        cartItemsLintel,
        cartItemsLeading,
        cartItemsDrive,
        cartItemsRoofAngle,
        cartItemsGateAccessories,
        summaryBoxNetPrice,
        cartPreviewDistributorPrice,
        language,
        nothingFound,noMatch,chosenButton, chooseButton,
        summaryBoxGateAccessories,
        cartPreviewOrderExtraInfo,
        cartPreviewOfferExtraInfo,
        cartPreviewValidUntil} = useTranslations()
    const dispatch = useDispatch();
    const creatorItem = useSelector(state => state.gateCreator);
    const [showVisualization, setShowVisualization] = useState(false);
    const [clickedItem, setClickedItem] = useState(1);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [searchText, setSearchText] = useState("");
    const location  = useLocation()
    const isMiniCreator = location.pathname.startsWith('/mini-creator');
    const {data: clientCart} = useQuery(["cart", creatorItem], () => authorizedGet(`/api/${creatorItem.isOffer ? "offer_carts" : "carts"}`), {
        placeholderData: [],
        enabled: !isMiniCreator,
    })
    const {data: accessoriesCategories} = useQuery("accessoriesCategories", () => authorizedGet("/api/accessory_categories"))


    const accessoriesFiltered = accessoriesCategories?.map(e => e.accessories.filter(item => item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())).map((accessory, index) => {
        return accessory
    }));
    const user = useSelector(state => state.user);
    const {data: client, isLoading} = useQuery(["client", user], () => authorizedGetById("/api/clients", user.userId), {
        keepPreviousData: true
    })
    const [options, setOptions] = useState([])
    const [companyOptions, setCompanyOptions] = useState([])
    useEffect(() => {
        if (client?.clients) {
            setOptions(client?.clients.map(c => ({value: JSON.stringify(c), label: c.name + " " + c.surname})))
            setCompanyOptions(client?.clients.map(c => ({
                value: JSON.stringify(c),
                label: c.company ? c.company : "Brak podanej firmy..."
            })))
        }
    }, [client]);
    const filteredAccessories = accessoriesFiltered?.filter(e => e.length !== 0)

    const [internalNumber, setInternalNumber] = useState(creatorItem?.clientNumber)
    const [receiver, setReceiver] = useState({
        receiverName: creatorItem?.receiverName,
        receiverCompany: creatorItem?.receiverCompany,
        receiverAddress: creatorItem?.receiverAddress,
        receiverZipCode: creatorItem?.receiverZipCode,
        receiverCity: creatorItem?.receiverCity,
        receiverCountry: creatorItem?.receiverCountry,
        receiverState: creatorItem?.receiverState,
        receiverPhone: creatorItem?.receiverPhone,
        receiverEmail: creatorItem?.receiverEmail,
    })
    const [receiverFromSelect, setReceiverFromSelect] = useState(null)
    const [isOneTimeOffer, setIsOneTimeOffer] = useState(false)
    const parsedReciever = useMemo(() => JSON.parse(receiverFromSelect), [receiverFromSelect])

    useEffect(() => {
        dispatch(creatorActions.updateCreator({
            ...creatorItem,
            clientToUpdate: receiver,
        }))
    }, [receiver]);

    useEffect(() => {
        if (parsedReciever) {
            setReceiver({
                receiverName: parsedReciever.name + " " + parsedReciever.surname,
                receiverCompany: parsedReciever.company,
                receiverAddress: parsedReciever.street,
                receiverZipCode: parsedReciever.zipCode,
                receiverCity: parsedReciever.city,
                receiverState: parsedReciever.province,
                receiverCountry: parsedReciever.country,
                receiverPhone: parsedReciever.phone,
                receiverEmail: parsedReciever.email,
            })
        }
    }, [parsedReciever])
    useEffect(() => {
        if (!receiverFromSelect && creatorItem?.receiverName && options.length > 0) {
            const matchingOption = options.find((option) =>
                option.label.toLowerCase().includes(creatorItem.receiverName.toLowerCase())
            );
            if (matchingOption) {
                setReceiverFromSelect(matchingOption.value);
            }
        }
    }, [creatorItem, options]);
    return (
        <>
        {!creatorItem?.isEdit && <div className="search-box">
                <input type="text" placeholder={accessoriesSearch} onChange={(e) => setSearchText(e.target.value)}/>
            </div>}
            {!creatorItem?.isEdit && <div className="content-items content-items__medium">
                {filteredAccessories?.length === 0 && <motion.p
                    style={{fontWeight: 500, fontSize: 24}}
                    initial={{opacity: 0, y: 5}}
                    animate={{opacity: 1, y: 0}}
                    transition={{
                        default: {
                            duration: .1
                        }
                    }
                    }
                >{nothingFound}</motion.p>}
                {searchText.trim().length > 0 ? <>
                    <div data-tab="1" className="content-accordion-expand show">
                        {accessoriesCategories?.map(e => e.accessories.filter(item => item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())).map((accessory, index) => {
                            return <motion.div
                                initial={{opacity: 0, y: 5}}
                                animate={{opacity: 1, y: 0}}
                                transition={{
                                    default: {
                                        duration: .1
                                    }
                                }
                                }
                                style={{
                                    backgroundImage: ` linear-gradient(black, white),url(${process.env.REACT_APP_API_UPLOADS + accessory?.image?.contentUrl})`,
                                    backgroundSize: "cover",
                                    backgroundBlendMode: "screen",
                                    maxWidth: '250px'
                                }}
                                key={accessory.id}
                                className="content-item content-item3">
                                <a className="content-item__icon" href="#">
                                    <img
                                        onClick={() => {
                                            dispatch(uiActions.chosenAccessory(accessory))
                                            dispatch(creatorActions.showDescription())
                                        }}
                                        src={eyeIcon}
                                        alt="icon"/>
                                </a>
                                <p className="content-item__title">{language === 'pl' ? accessory.name : accessory?.translations ? accessory.translations[language].name : '-'}</p>
                                <button
                                    disabled={disabledBtn}
                                    onClick={() => {
                                        setDisabledBtn(true)
                                        if (creatorItem?.accessories?.some(e => e.id === accessory.id)) {
                                            authorizedDeleteWithId({
                                                url: `/api/${creatorItem.isOffer ? "offer_cart_accessories" : "cart_accessories"}`,
                                                id: accessory.id
                                            })
                                            dispatch(creatorActions.deleteItemAccessories(accessory.id))
                                        } else {
                                            authorizedGetById("/api/accessories", accessory.id).then(r => {

                                                return r
                                            }).then(e => {
                                                dispatch(creatorActions.updateItemDetailAccessories({
                                                    ...e,
                                                    quantity: 1,
                                                    totalPrice: e.price
                                                }))
                                                setDisabledBtn(false)
                                            })
                                        }

                                        setTimeout(() => {
                                            setDisabledBtn(false)
                                        }, 1000)
                                    }
                                    }
                                    className={`content-item__link ${creatorItem.accessories?.some(e => e?.id === accessory.id) ? "chosen" : null}`}>
                                    {creatorItem.accessories?.some(e => e?.id === accessory.id) ? chosenButton : chooseButton}
                                </button>
                            </motion.div>
                        }))}
                    </div>
                </> : null}
                {searchText.trim().length === 0 && <div className="items-container show">
                    {accessoriesCategories?.filter(aCat => aCat.accessories.length > 0).sort((a, b) => a.name - b.name).map(accessoryType => {
                        return <div
                            key={accessoryType.id}
                            className="accordion-container">
                            <div className="content-accordion__item">
                                <p className="content-accordion__title">{language === 'pl' ? accessoryType.name : accessoryType?.translations ? accessoryType.translations[language].name : '-'}</p>
                                <div className="accordion-icons flex">
                                    <img
                                        onClick={(e) => {
                                            setClickedItem(clickedItem === accessoryType.id ? null : accessoryType.id)
                                        }}
                                        data-target="1"
                                        className={`accordion-icons__expand ${clickedItem === accessoryType.id ? "show" : ""}`}
                                        src={expandIcon}
                                        alt="accordion icon"/>
                                </div>
                            </div>
                            {clickedItem === accessoryType.id &&
                                <div data-tab="1" className="content-accordion-expand show">
                                    {accessoryType.accessories.length === 0 &&
                                        <p>{noMatch}</p>}
                                    {accessoryType.accessories.length > 0 && accessoryType.accessories.map((accessory, index) => {
                                        return <motion.div
                                            initial={{opacity: 0, y: 5}}
                                            animate={{opacity: 1, y: 0}}
                                            transition={{
                                                default: {
                                                    duration: .1
                                                }
                                            }
                                            }
                                            style={{
                                                backgroundImage: ` linear-gradient(black, white),url(${process.env.REACT_APP_API_UPLOADS + accessory?.image?.contentUrl})`,
                                                backgroundSize: "cover",
                                                backgroundBlendMode: "screen",
                                                maxWidth: '250px',
                                                flex: 1
                                            }}
                                            key={accessory.id}
                                            className="content-item content-item3">
                                            <a className="content-item__icon" href="#">
                                                <img
                                                    onClick={() => {
                                                        dispatch(uiActions.chosenAccessory(accessory))
                                                        dispatch(creatorActions.showDescription())
                                                    }}
                                                    src={eyeIcon}
                                                    alt="icon"/>
                                            </a>
                                            <p className="content-item__title">{language === 'pl' ? accessory.name : accessory?.translations ? accessory.translations[language].name : '-'}</p>
                                            <button
                                                disabled={disabledBtn}
                                                onClick={() => {
                                                    setDisabledBtn(true)
                                                    if (creatorItem?.accessories?.some(e => e.id === accessory.id)) {
                                                        authorizedDeleteWithId({
                                                            url: `/api/${creatorItem.isOffer ? "offer_cart_accessories" : "cart_accessories"}`,
                                                            id: accessory.id
                                                        })
                                                        dispatch(creatorActions.deleteItemAccessories(accessory.id))
                                                    } else {
                                                        authorizedGetById("/api/accessories", accessory.id).then(r => {

                                                            return r
                                                        }).then(e => {
                                                            dispatch(creatorActions.updateItemDetailAccessories({
                                                                ...e,
                                                                quantity: 1,
                                                                totalPrice: e.price
                                                            }))
                                                            setDisabledBtn(false)
                                                        })
                                                    }
                                                    setTimeout(() => {
                                                        setDisabledBtn(false)
                                                    }, 1000)
                                                }
                                                }
                                                className={`content-item__link ${creatorItem.accessories?.some(e => e?.id === accessory.id) ? "chosen" : null}`}>
                                                {creatorItem.accessories?.some(e => e?.id === accessory.id) ? chosenButton : chooseButton}
                                            </button>
                                        </motion.div>
                                    })}
                                </div>}
                        </div>
                    })}
                </div>}
            </div>}
            {creatorItem?.isEdit && <section style={{marginTop:50}} className={'order-view'}>
                <div className="search-box show">
                    <div className="search-box-title-container">
                        <h3>{cartPreviewDataToOffer}</h3>
                        {user?.isDistributor && <div className="search-box-checkbox">
                            <Checkbox size={'sm'} onChange={() => setIsOneTimeOffer(prev => !prev)}/>
                            <p>{cartPreviewOneTimeOffer}</p>
                        </div>}
                    </div>

                    <div className="search-box-inputs">
                        <div>
                            <div>
                                <label
                                    htmlFor="">{cartPreviewOfferInternalNumber}</label>
                                <input
                                    value={internalNumber}
                                    onChange={e => {
                                        setInternalNumber(e.target.value)
                                    }}
                                    className="input-long input"
                                    type="text"
                                    placeholder={`${cartPreviewOfferInternalNumber}...`}/>
                            </div>
                            {<div>
                                <label htmlFor="">{historyClientName}</label>
                                {!isOneTimeOffer && user?.isDistributor ? <Select
                                    data={options}
                                    value={receiverFromSelect}
                                    className="input-long input"
                                    placeholder={historyClientName}
                                    nothingFound={cartPreviewNoClients}
                                    searchable={true}
                                    onChange={v => {
                                        setReceiverFromSelect(v)
                                    }}
                                    filter={(value, item) =>
                                        item?.value?.toLowerCase()?.includes(value.toLowerCase().trim())
                                    }
                                /> : <input
                                    value={receiver.receiverName ? receiver.receiverName : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverName: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder={historyClientName}
                                />}
                            </div>}
                        </div>
                    </div>
                    {creatorItem?.isEdit && <>
                        <div className="search-box-inputs" style={{marginTop: 10}}>
                            <div>
                                <div>
                                    <label htmlFor="">{historyClientCompany}</label>
                                    {!isOneTimeOffer && user?.isDistributor ? <Select
                                        data={companyOptions}
                                        className="input-long input"
                                        value={receiverFromSelect}
                                        placeholder={historyClientCompany}
                                        nothingFound={cartPreviewNoClients}
                                        searchable={true}
                                        onChange={v => {
                                            setReceiverFromSelect(v)
                                        }}
                                        filter={(value, item) =>
                                            item?.value?.toLowerCase()?.includes(value.toLowerCase().trim())
                                        }
                                    /> : <input
                                        value={receiver.receiverCompany ? receiver.receiverCompany : null}
                                        onChange={e => setReceiver(prev => ({
                                            ...prev,
                                            receiverCompany: e.target.value
                                        }))}
                                        className="input-long"
                                        type="text" placeholder={historyClientCompany}
                                    />}
                                </div>
                                <div>
                                    <label htmlFor="">{historyClientAddress}</label>
                                    <input
                                        value={receiver.receiverAddress ? receiver.receiverAddress : null}
                                        onChange={e => setReceiver(prev => ({
                                            ...prev,
                                            receiverAddress: e.target.value
                                        }))}
                                        className="input-long"
                                        type="text" placeholder={historyClientAddress}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="search-box-inputs" style={{marginTop: 10}}>
                            <div>
                                <div>
                                    <label htmlFor="">{historyClientCity}</label>
                                    <input
                                        value={receiver.receiverCity ? receiver.receiverCity : null}
                                        onChange={e => setReceiver(prev => ({
                                            ...prev,
                                            receiverCity: e.target.value
                                        }))}
                                        className="input-long"
                                        type="text" placeholder={historyClientCity}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="">{cartPreviewClientCounty}</label>
                                    <input
                                        value={receiver.receiverState ? receiver.receiverState : null}
                                        onChange={e => setReceiver(prev => ({
                                            ...prev,
                                            receiverState: e.target.value
                                        }))}
                                        className="input-long"
                                        type="text" placeholder={cartPreviewClientCounty}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="search-box-inputs" style={{marginTop: 10}}>
                            <div>
                                <div>
                                    <label htmlFor="">{historyClientZipCode}</label>
                                    <input
                                        value={receiver.receiverZipCode ? receiver.receiverZipCode : null}
                                        onChange={e => setReceiver(prev => ({
                                            ...prev,
                                            receiverZipCode: e.target.value
                                        }))}
                                        className="input-long"
                                        type="text" placeholder={historyClientZipCode}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="">{historyClientCountry}</label>
                                    <input
                                        value={receiver.receiverCountry ? receiver.receiverCountry : null}
                                        onChange={e => setReceiver(prev => ({
                                            ...prev,
                                            receiverCountry: e.target.value
                                        }))}
                                        className="input-long"
                                        type="text" placeholder={historyClientCountry}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="search-box-inputs" style={{marginTop: 10}}>
                            <div>
                                <div>
                                    <label htmlFor="">{historyClientPhone}</label>
                                    <input
                                        value={receiver.receiverPhone ? receiver.receiverPhone : null}
                                        onChange={e => setReceiver(prev => ({
                                            ...prev,
                                            receiverPhone: e.target.value
                                        }))}
                                        className="input-long"
                                        type="text" placeholder={historyClientPhone}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="">{cartPreviewClientEmail}</label>
                                    <input
                                        value={receiver.receiverEmail ? receiver.receiverEmail : null}
                                        onChange={e => setReceiver(prev => ({
                                            ...prev,
                                            receiverEmail: e.target.value
                                        }))}
                                        className="input-long"
                                        type="text" placeholder={cartPreviewClientEmail}
                                    />
                                </div>
                            </div>
                        </div>
                    </>}

                </div>
            </section>}
        </>);
};

export default Accessories;
